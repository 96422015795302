var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Cookies from 'js-cookie';
import moment from 'moment';
import { call, put, select, spawn, takeEvery, takeLatest, } from 'redux-saga/effects';
import { EXAM_TYPE } from './containers/constant/constants';
import { service } from './services/service';
import * as Action from './store/actions';
import { GET_ALL_META_DATA, GET_STUDENT_EXAM_RESULT_ANALYSIS, GET_STUDENT_TAKEN_EXAM, POST_STUDENT_FORGOT_PASSWORD, POST_STUDENT_FORGOT_PASSWORD_VERIFY, POST_STUDENT_LOGIN, POST_STUDENT_PASSWORD_RESET, POST_STUDENT_SIGN_UP, POST_TEACHER_LOGIN, POST_TEACHER_PASSWORD_RESET, POST_TEACHER_SIGN_UP, POST_TENANT_FORGOT_PASSWORD, POST_TENANT_FORGOT_PASSWORD_VERIFY, } from './store/authContants';
import { GET_ALL_EXAMS, GET_ALL_EXAM_SET_DETAILS, GET_EXAM_BY_ID, GET_QUESTIONS_BY_EXAMSET, GET_QUESTIONS_ON_PAGE_LOAD, GET_TENANT_DETAIL_BY_CODE, POST_CHECK_COUPON_CODE, POST_CONTACT_US_DETAIL, POST_PAGE_AUDIT, POST_PAYPAL_PAYMENT_INITIATE, POST_PURCHASE_STATUS, POST_SEARCH_EXAM, } from './store/constants';
import { GET_STUDENT_EXAM_QUESTION_CHOICE, GET_STUDENT_EXAM_RESULT, POST_BUY_EXAM, POST_EXAM_AUDIT, POST_EXAM_EVERY_CHOICE_CLICK, POST_EXAM_START, POST_EXAM_SUBMIT, } from './store/studentExamConstants';
import { getCompressedData } from './utils/compressionUtils';
function* getExamByIdStore(action) {
    const exams = yield select((state) => state.exam.exams);
    return exams === null || exams === void 0 ? void 0 : exams[action.payload];
}
function* getExamSetByExamId(action) {
    const examSets = yield select((state) => state.exam.examSet);
    return examSets === null || examSets === void 0 ? void 0 : examSets[action.examID];
}
function* getQuestionByExamSetId(action) {
    const questions = yield select((state) => state.exam.questions);
    return questions === null || questions === void 0 ? void 0 : questions[action.examSetID];
}
function* getAllExams() {
    try {
        const exams = yield select((state) => state.exam.exams);
        if (exams.length) {
            yield put(Action.updateLoader({ loader: 'examListLoader', value: false }));
            return;
        }
        const res = yield call(service.getAllExams);
        yield put(Action.getAllExamsSuccess(res));
    }
    catch (e) {
        console.log(e);
    }
}
function* getAllExamSetDetails(action) {
    try {
        const { examID } = action.payload.params;
        const examSet = yield call(getExamSetByExamId, { examID });
        if (examSet) {
            yield put(Action.saveExamSetDetailsSuccess({ res: examSet, examID }));
            return;
        }
        const res = yield call(service.getAllExamSetDetails, { examID });
        // if (!res?.length) navigate(`/exams/${examID}/sets`);
        yield put(Action.saveExamSetDetailsSuccess({ res, examID }));
    }
    catch (e) {
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMsg: 'Error getting exam set details',
        }));
    }
}
function* getExamById(action) {
    try {
        const { examID } = action.payload.params;
        const exam = yield call(getExamByIdStore, {
            payload: examID,
        });
        yield call(getAllExamSetDetails, {
            payload: { params: { examID } },
        });
        if (exam) {
            yield put(Action.updateLoader({ loader: 'examLoader', value: false }));
            return;
        }
        const res = yield call(service.getExamById, { examID });
        yield put(Action.getExamByIdSuccess(res));
    }
    catch (e) {
        console.log(e);
    }
}
function* getQuestionsByExamSet(action) {
    try {
        const { exam } = action;
        const { examSetID } = action.payload.params;
        const res = yield call(service.getQuestionsByExamSet, {
            examSetID,
            tenantID: exam.tenantID,
        });
        yield put(Action.getQuestionsByExamSetSuccess({ res, examSetID }));
    }
    catch (e) {
        console.log(e);
    }
}
function* getQuestionPageLoad(action) {
    const { examID } = action.payload.params;
    yield call(getExamById, { payload: action.payload });
    const exam = yield call(getExamByIdStore, { payload: examID });
    const examSets = yield call(getExamSetByExamId, { examID });
    if (!examSets)
        yield call(getAllExamSetDetails, action);
    yield call(getQuestionsByExamSet, Object.assign(Object.assign({}, action), { exam }));
    yield put(Action.updateLoader({ loader: 'learningSetLoader', value: false }));
}
function* postStudentLogin(action) {
    var _a;
    try {
        // const { IPv4 } = yield call(service.getIpGeoAddress);
        const res = yield call(service.postStudentLogin, Object.assign(Object.assign({}, action.payload), { loginIP: '' }));
        Cookies.set('user', getCompressedData(JSON.stringify(res)), {
            expires: 0.5,
        });
        yield put(Action.postStudentLoginSuccess(Object.assign({}, res)));
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'success',
            snackbarMsg: 'Logged In Successfully',
        }));
        yield spawn(getStudentTakenExam);
    }
    catch (e) {
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMsg: (_a = e.data.message) !== null && _a !== void 0 ? _a : 'Error while Logging In',
        }));
    }
}
function* postTeacherLogin(action) {
    var _a;
    try {
        // const { IPv4 } = yield call(service.getIpGeoAddress);
        const res = yield call(service.postTeacherLogin, Object.assign(Object.assign({}, action.payload), { loginIP: '' }));
        yield put(Action.postTeacherLoginSuccess({}));
        Cookies.set('user', getCompressedData(JSON.stringify(res)), {
            domain: process.env.REACT_APP_TEACHER_PORTAL_URL,
            expires: 0.5,
        });
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'success',
            snackbarMsg: 'Logged In Successfully.Will be redirected to TeacherPortal in few seconds.',
        }));
        setTimeout(() => {
            var _a;
            window.location.replace((_a = process.env.REACT_APP_TEACHER_PORTAL_URL) !== null && _a !== void 0 ? _a : '');
        }, 2000);
    }
    catch (e) {
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMsg: (_a = e.data.message) !== null && _a !== void 0 ? _a : 'Error while Logging In',
        }));
    }
}
function* postStudentSignUp(action) {
    try {
        const _a = action.payload, { tenantID } = _a, payload = __rest(_a, ["tenantID"]);
        const res = yield call(service.postStudentSignUp, payload);
        console.log(tenantID);
        if (!tenantID) {
            yield put(Action.postStudentSignUpSuccess(Object.assign({}, res)));
            Cookies.set('user', getCompressedData(JSON.stringify(res)), {
                expires: 0.5,
            });
            yield put(Action.setSnackbar({
                snackbarOpen: true,
                snackbarType: 'success',
                snackbarMsg: 'Signed up Successfully',
            }));
        }
        else {
            yield call(postStudentTenantMapping, {
                tenantID,
                studentID: res.studentId,
            });
            yield put(Action.signUpClose());
        }
    }
    catch (e) {
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMsg: 'SignUp Failed. Please try again.',
        }));
    }
}
function* postTeacherSignUp(action) {
    try {
        const res = yield call(service.postTeacherSignUp, action.payload);
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'success',
            snackbarMsg: 'Signed up Successfully.Please login to navigate teacher portal.',
        }));
        yield put(Action.postTeacherSignUpSuccess());
    }
    catch (e) {
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMsg: 'Error occurred while Sign Up.Please contact support.',
        }));
    }
}
function* postStudentPasswordReset(action) {
    var _a, _b;
    try {
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'info',
            snackbarMsg: 'Password Reset In Progress.',
        }));
        const res = yield call(service.postStudentPasswordReset, action.payload);
        yield put(Action.postStudentPasswordResetSuccess());
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'success',
            snackbarMsg: 'Password Reset Successfully.',
        }));
    }
    catch (e) {
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMsg: (_b = (_a = e === null || e === void 0 ? void 0 : e.data) === null || _a === void 0 ? void 0 : _a.message) !== null && _b !== void 0 ? _b : 'Error occurred while resetting the password.',
        }));
    }
}
function* postTeacherPasswordReset(action) {
    try {
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'info',
            snackbarMsg: 'Password Reset In Progress.',
        }));
        yield call(service.postTeacherPasswordReset, action.payload);
        yield put(Action.postTeacherPasswordResetSuccess());
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'success',
            snackbarMsg: 'Password Reset Successfully.',
        }));
    }
    catch (e) {
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMsg: 'Error occurred while resetting the password.',
        }));
    }
}
function* postExamStart(action) {
    var _a;
    try {
        // const { IPv4, ...geoLocation } = yield call(service.getIpGeoAddress);
        const user = yield select((state) => state.auth.user);
        const payload = Object.assign(Object.assign({}, action.payload.payload), { ipAddress: '', studentID: (_a = user.userID) !== null && _a !== void 0 ? _a : user.studentId, createdBy: user.loginID, modifiedBy: user.loginID });
        const res = yield call(service.postExamStart, {
            payload,
            examID: action.payload.examID,
        });
        yield put(Action.postExamStartSuccess(Object.assign(Object.assign({}, res), { examStarted: true })));
        yield put(Action.updateStudentExams({
            examID: action.payload.examID,
            examSetID: res.examSetID,
            transactionID: res.examSetID,
            examType: EXAM_TYPE.CONTINUE_EXAM,
        }));
    }
    catch (e) {
        yield put(Action.postExamStartFailure({ examStarted: false }));
    }
}
function* postExamSubmit(action) {
    try {
        const { studentExamID, navigate, fullScreenExit } = action.payload;
        yield call(service.postExamSubmit, { studentExamID });
        navigate('/', { replace: true });
        fullScreenExit();
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'success',
            snackbarMsg: 'Congratulations. Exam Submitted Successfully',
        }));
    }
    catch (e) {
        console.log(e);
    }
}
function* postExamAudit(action) {
    var _a;
    try {
        const _b = action.payload, { examID } = _b, sequenceData = __rest(_b, ["examID"]);
        const { user } = yield select((state) => state.auth);
        const payload = {
            examSetID: sequenceData.set,
            studentID: (_a = user.userID) !== null && _a !== void 0 ? _a : user.studentId,
            sequenceData: JSON.stringify(sequenceData),
        };
        const res = yield spawn(service.postExamAudit, {
            examID,
            payload,
        });
    }
    catch (e) {
        console.log(e);
    }
}
function* postExamEveryChoiceClick(action) {
    try {
        let _a = action.payload, { examID, question } = _a, payload = __rest(_a, ["examID", "question"]);
        payload = {
            studentExamID: payload.studentExamID,
            questionID: question.questionID,
            choiceID: payload.choiceID,
            startTime: new Date(),
            endtime: new Date(),
        };
        const res = yield spawn(service.postExamEveryChoiceClick, {
            examID,
            payload,
        });
    }
    catch (e) {
        console.log(e);
    }
}
function* getStudentTakenExam() {
    var _a;
    try {
        const { user } = yield select((state) => state.auth);
        if (!(user === null || user === void 0 ? void 0 : user.userID))
            return;
        const res = yield call(service.getStudentTakenExam, {
            studentID: (_a = user.userID) !== null && _a !== void 0 ? _a : user.studentId,
        });
        yield put(Action.getStudentTakenExamSuccess(res));
    }
    catch (e) {
        console.log(e);
    }
}
function* getStudentExamResult(action) {
    try {
        const { studentExamID } = action.payload.params;
        const res = yield call(service.getStudentExamResult, {
            studentExamID,
        });
        yield put(Action.getStudentExamResultSuccess({ studentExamID, result: res }));
    }
    catch (e) {
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMsg: 'Error occurred while fetching the result please try again.',
        }));
        throw Error('error occur while fetching result.');
    }
}
function* getStudentExamQuestionChoice(action) {
    try {
        const { studentExamID, examSetID } = action.payload;
        const res = yield call(service.getStudentExamQuestionChoice, {
            studentExamID,
        });
        yield put(Action.getStudentExamQuestionChoiceSuccess({ res, examSetID }));
    }
    catch (e) {
        console.log(e);
    }
}
function* postBuyExam(action) {
    try {
        const payment = yield call(service.postPaymentDetails, Object.assign({}, action.payload));
        window.location.replace(payment);
    }
    catch (e) {
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMsg: 'Error occurred while saving exam',
        }));
    }
}
function* postSearchExam(action) {
    try {
        const res = yield call(service.postExamSearchRegistry, {
            searchText: action.payload.search,
            sourceIP: '',
            tenantID: 'anonymous',
        });
        yield put(Action.postSearchExamSuccess(res));
    }
    catch (e) {
        console.log(e);
    }
}
function* postTenantForgotPassword(action) {
    var _a;
    try {
        const res = yield call(service.postTenantForgotPassword, Object.assign({}, action.payload));
        yield put(Action.forgotPasswordModalClose());
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'success',
            snackbarMsg: res.message,
        }));
    }
    catch (e) {
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMsg: (_a = e.data.message) !== null && _a !== void 0 ? _a : 'Error occurred',
        }));
    }
}
function* postStudentForgotPassword(action) {
    var _a;
    try {
        const res = yield call(service.postStudentForgotPassword, Object.assign({}, action.payload));
        yield put(Action.forgotPasswordModalClose());
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'success',
            snackbarMsg: res.message,
        }));
    }
    catch (e) {
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMsg: (_a = e.data.message) !== null && _a !== void 0 ? _a : 'Error occurred',
        }));
    }
}
function* postStudentExamResultAnalysis(action) {
    var _a, _b;
    try {
        const { user } = yield select((state) => state.auth);
        const { examID } = action.payload.params;
        const res = yield call(service.postStudentExamResultAnalysis, {
            examID,
            data: { studentID: (_a = user.userID) !== null && _a !== void 0 ? _a : user.studentId },
        });
        yield put(Action.getStudentExamResultAnalysisSuccess({ examID, data: res }));
    }
    catch (e) {
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMsg: (_b = e.data.message) !== null && _b !== void 0 ? _b : 'Error occurred',
        }));
    }
}
function* postPurchaseStatus(action) {
    var _a;
    try {
        yield call(getExamById, Object.assign({}, action));
        const res = yield call(service.postPaymentStatusCheck, action);
    }
    catch (e) {
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMsg: (_a = e.data.message) !== null && _a !== void 0 ? _a : 'Error occurred',
        }));
    }
}
function* postTenantForgotPasswordVerify(action) {
    var _a, _b;
    try {
        const { requestId, password, navigate } = action.payload;
        const res = yield call(service.postTenantForgotPasswordVerify, {
            requestId,
            password,
        });
        navigate('/', { replace: true });
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'success',
            snackbarMsg: res.message,
        }));
    }
    catch (e) {
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMsg: (_b = (_a = e === null || e === void 0 ? void 0 : e.data) === null || _a === void 0 ? void 0 : _a.message) !== null && _b !== void 0 ? _b : 'Error occurred',
        }));
    }
}
function* postStudentForgotPasswordVerify(action) {
    var _a, _b;
    try {
        const { requestId, password, navigate } = action.payload;
        const res = yield call(service.postStudentForgotPasswordVerify, {
            requestId,
            password,
        });
        navigate('/', { replace: true });
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'success',
            snackbarMsg: 'Password reset successful.',
        }));
    }
    catch (e) {
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMsg: (_b = (_a = e === null || e === void 0 ? void 0 : e.data) === null || _a === void 0 ? void 0 : _a.message) !== null && _b !== void 0 ? _b : 'Error occurred',
        }));
    }
}
function* postContactUsDetail(action) {
    var _a, _b;
    try {
        const res = yield call(service.postContactUsDetail, Object.assign({}, action.payload));
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'success',
            snackbarMsg: res.message,
        }));
    }
    catch (e) {
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMsg: (_b = (_a = e === null || e === void 0 ? void 0 : e.data) === null || _a === void 0 ? void 0 : _a.message) !== null && _b !== void 0 ? _b : 'Error occurred',
        }));
    }
}
function* postPageAudit(action) {
    try {
        const sessionID = Cookies.get('sessionID');
        const user = yield select((state) => state.auth.user);
        console.log(moment().format('YYYY-MM-DD HH:mm:ss:SSS Z'));
        yield spawn(service.postPageAudit, {
            sessionID,
            dateTime: moment().format('YYYY-MM-DD HH:mm:ss:SSS Z'),
            page: action.payload.page,
            IP: '',
            createdBy: user.loggedIn ? user === null || user === void 0 ? void 0 : user.emailId : 'anonymous',
        });
    }
    catch (e) {
        console.log('PageAudit Error');
    }
}
function* postPaypalPaymentInitiate(action) {
    try {
        const payment = yield call(service.postPaypalPaymentInitiate, Object.assign({}, action.payload));
        window.location.replace(payment);
    }
    catch (e) {
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMsg: 'Error occurred while saving exam',
        }));
    }
}
function* postCheckCouponCode(action) {
    try {
        const { user } = yield select((state) => state.auth);
        const payment = yield call(service.postCheckCouponCode, Object.assign(Object.assign({}, action.payload), { studentEmailID: user.emailId }));
        yield put(Action.postCheckCouponCodeSuccess(Object.assign(Object.assign({}, payment), action.payload)));
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'success',
            snackbarMsg: 'Coupon applied successfully',
        }));
    }
    catch (e) {
        yield put(Action.postCheckCouponCodeFailure({ isValid: false }));
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMsg: 'Coupon code is not valid.',
        }));
    }
}
function* getTenantDetailByCode(action) {
    try {
        const res = yield call(service.getTenantDetailByCode, Object.assign({}, action.payload));
        yield put(Action.getTenantDetailByCodeSuccess(res));
        Cookies.set('tenant', getCompressedData(JSON.stringify(res)));
        yield call(getAllMetaData);
    }
    catch (e) {
        yield put(Action.postCheckCouponCodeFailure({ isValid: false }));
    }
}
function* postStudentTenantMapping(action) {
    try {
        yield call(service.postStudentTenantMapping, Object.assign({}, action));
        yield put(Action.setSnackbar({
            snackbarOpen: true,
            snackbarType: 'success',
            snackbarMsg: 'Account created successfully. An Email will be sent once your account is being approved.',
        }));
    }
    catch (e) {
        yield put(Action.postCheckCouponCodeFailure({ isValid: false }));
    }
}
function* getAllMetaData() {
    try {
        const { tenantDetail } = yield select((state) => state.exam);
        const res = yield call(service.getAllMetaInfo, {
            tenantID: tenantDetail.tenantID,
        });
        yield put(Action.getAllMetaDataSuccess(res));
        console.log(res);
        Cookies.set('tenant-metadata', getCompressedData(JSON.stringify(res)));
    }
    catch (e) {
        yield put(Action.postCheckCouponCodeFailure({ isValid: false }));
    }
}
function* saga() {
    yield takeLatest(GET_ALL_EXAMS, getAllExams);
    yield takeEvery(GET_EXAM_BY_ID, getExamById);
    yield takeLatest(GET_ALL_EXAM_SET_DETAILS, getAllExamSetDetails);
    yield takeLatest(GET_QUESTIONS_BY_EXAMSET, getQuestionsByExamSet);
    yield takeLatest(GET_QUESTIONS_ON_PAGE_LOAD, getQuestionPageLoad);
    yield takeLatest(POST_STUDENT_LOGIN, postStudentLogin);
    yield takeLatest(POST_TEACHER_LOGIN, postTeacherLogin);
    yield takeLatest(POST_STUDENT_SIGN_UP, postStudentSignUp);
    yield takeLatest(POST_TEACHER_SIGN_UP, postTeacherSignUp);
    yield takeLatest(POST_STUDENT_PASSWORD_RESET, postStudentPasswordReset);
    yield takeLatest(POST_TEACHER_PASSWORD_RESET, postTeacherPasswordReset);
    yield takeLatest(POST_EXAM_START, postExamStart);
    yield takeLatest(POST_EXAM_SUBMIT, postExamSubmit);
    yield takeEvery(POST_EXAM_AUDIT, postExamAudit);
    yield takeEvery(POST_EXAM_EVERY_CHOICE_CLICK, postExamEveryChoiceClick);
    yield takeLatest(GET_STUDENT_TAKEN_EXAM, getStudentTakenExam);
    yield takeLatest(GET_STUDENT_EXAM_RESULT, getStudentExamResult);
    yield takeLatest(GET_STUDENT_EXAM_QUESTION_CHOICE, getStudentExamQuestionChoice);
    yield takeLatest(POST_BUY_EXAM, postBuyExam);
    yield takeLatest(POST_SEARCH_EXAM, postSearchExam);
    yield takeLatest(POST_TENANT_FORGOT_PASSWORD, postTenantForgotPassword);
    yield takeLatest(POST_STUDENT_FORGOT_PASSWORD, postStudentForgotPassword);
    yield takeLatest(POST_TENANT_FORGOT_PASSWORD_VERIFY, postTenantForgotPasswordVerify);
    yield takeLatest(GET_STUDENT_EXAM_RESULT_ANALYSIS, postStudentExamResultAnalysis);
    yield takeLatest(POST_PURCHASE_STATUS, postPurchaseStatus);
    yield takeLatest(POST_CONTACT_US_DETAIL, postContactUsDetail);
    yield takeEvery(POST_PAGE_AUDIT, postPageAudit);
    yield takeLatest(POST_PAYPAL_PAYMENT_INITIATE, postPaypalPaymentInitiate);
    yield takeLatest(POST_STUDENT_FORGOT_PASSWORD_VERIFY, postStudentForgotPasswordVerify);
    yield takeLatest(POST_CHECK_COUPON_CODE, postCheckCouponCode);
    yield takeLatest(GET_TENANT_DETAIL_BY_CODE, getTenantDetailByCode);
    yield takeLatest(GET_ALL_META_DATA, getAllMetaData);
}
export default saga;
