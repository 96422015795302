import { Button, Typography } from '@mui/material';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { EXAM_TYPE } from '../../containers/constant/constants';
import { useStyle } from '../../styles/style';
import { getLearningSet } from '../../store/actions';
const SetButtons = ({ examID, examSet }) => {
    const classes = useStyle();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [viewAnalysis, setViewAnalysis] = useState(false);
    const handleStartExam = ({ examSetID }) => {
        navigate(`/exam`, {
            state: { examID, examSetID, type: EXAM_TYPE.START_EXAM },
        });
    };
    const handleContinueExam = ({ examSetID, transactionID }) => {
        navigate('/exam', {
            state: {
                examID,
                examSetID,
                type: EXAM_TYPE.CONTINUE_EXAM,
                transactionID,
            },
        });
    };
    const handleLearningSet = ({ examSetID, transactionID }) => {
        dispatch(getLearningSet());
        navigate('/learning-exam', {
            state: {
                examID,
                examSetID,
                transactionID,
            },
        });
    };
    const handleResultPreview = ({ examSetID, transactionID }) => {
        navigate(`/${transactionID}/result`, {
            state: { examID, examSetID, transactionID },
        });
    };
    useEffect(() => {
        examSet.forEach((set) => {
            if (set.examType === EXAM_TYPE.VIEW_RESULT)
                setViewAnalysis(true);
        });
    }, [examSet]);
    return (React.createElement("div", { className: clsx(classes.setButtonMain, classes.displayFlex) },
        React.createElement("div", { className: classes.setButton }, examSet === null || examSet === void 0 ? void 0 : examSet.map((set) => (React.createElement("div", { key: set.examSetID, className: clsx(classes.rowAlign, classes.rightBorder) },
            React.createElement(Typography, { variant: "body2" },
                set.examSetName,
                " : "),
            set.examType === EXAM_TYPE.START_EXAM && (React.createElement(Button, { disableFocusRipple: true, onClick: () => handleStartExam({ examSetID: set.examSetID }) }, "START EXAM")),
            set.examType === EXAM_TYPE.VIEW_RESULT && (React.createElement(Button, { disableFocusRipple: true, onClick: () => handleResultPreview({
                    examSetID: set.examSetID,
                    transactionID: set.transactionID,
                }) }, "VIEW RESULT")),
            set.examType === EXAM_TYPE.CONTINUE_EXAM && (React.createElement(Button, { disableFocusRipple: true, onClick: () => handleContinueExam({
                    examSetID: set.examSetID,
                    transactionID: set.transactionID,
                }) }, "CONTINUE EXAM")),
            set.examType === EXAM_TYPE.LEARNING && (React.createElement(Button, { disableFocusRipple: true, onClick: () => handleLearningSet({
                    examSetID: set.examSetID,
                    transactionID: set.transactionID,
                }) }, "START LEARNING SET")))))),
        viewAnalysis && (React.createElement(Button, { disableFocusRipple: true, onClick: () => navigate(`/${examID}/result-analysis`), variant: "contained", fullWidth: true }, "VIEW ANALYSIS"))));
};
export default SetButtons;
