import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Actions from '../../store/actions';
import { useStyle } from '../../styles/style';
import { useGetExamById, useGetExamSetByExamId, useQuestionsByExamSetId, } from '../../utils/hookUtils';
import LearningExamBody from './LearningExamBody';
import LearningExamTopBar from './LearningExamTopBar';
import { PRIMARY_COLOR } from '../../styles/colors';
import { pxToRem } from '../../utils/themeUtils';
const LearningExam = ({ examID, examSetID }) => {
    var _a;
    const classes = useStyle();
    const { activeExam } = useSelector((state) => state.studentExam);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { examStarted } = activeExam;
    const [activeQuestion, setActiveQuestion] = useState(1);
    const exam = useGetExamById(examID);
    const examSets = useGetExamSetByExamId(examID);
    const examSet = examSets.filter((set) => set.examSetID === examSetID)[0];
    const question = (_a = useQuestionsByExamSetId(examSetID)) === null || _a === void 0 ? void 0 : _a[activeQuestion - 1];
    const { noOfQuestionsPerSet } = exam;
    useEffect(() => {
        if (!examStarted) {
            navigate('/my-learning', { replace: true });
            dispatch(Actions.setSnackbar({
                snackbarOpen: true,
                snackbarType: 'error',
                snackbarMsg: 'Error while starting the exam please contact support.',
            }));
        }
    }, [examStarted]);
    const handleNextClick = () => {
        setActiveQuestion((prev) => prev + 1);
    };
    const handlePreviousClick = () => {
        setActiveQuestion((prev) => prev - 1);
    };
    return (React.createElement("div", { className: classes.questionParent, onContextMenu: () => false, style: {
            background: PRIMARY_COLOR.GREY10,
            height: '92vh',
            padding: pxToRem(32),
        } },
        React.createElement("div", { className: classes.examQuestionMain, style: { width: '94vw' } },
            React.createElement(LearningExamTopBar, { exam: exam }),
            React.createElement(LearningExamBody, { activeQuestion: activeQuestion, question: question, noOfQuestionsPerSet: noOfQuestionsPerSet, handleNextClick: handleNextClick, handlePreviousClick: handlePreviousClick }))));
};
export default LearningExam;
