export const GET_ALL_EXAMS = 'GET_ALL_EXAMS';
export const GET_ALL_EXAMS_SUCCESS = 'GET_ALL_EXAMS_SUCCESS';
export const GET_EXAM_BY_ID = 'GET_EXAM_BY_ID';
export const SET_SNACKBAR = 'SET_SNACKBAR';
export const GET_ALL_EXAM_SET_DETAILS = 'GET_ALL_EXAM_SET_DETAILS';
export const SAVE_EXAM_SET_DETAILS_SUCCESS = 'SAVE_EXAM_SET_DETAILS_SUCCESS';
export const GET_QUESTIONS_BY_EXAMSET = 'GET_QUESTIONS_BY_EXAMSET';
export const GET_QUESTIONS_BY_EXAMSET_SUCCESS = 'GET_QUESTIONS_BY_EXAMSET_SUCCESS';
export const GET_QUESTIONS_ON_PAGE_LOAD = 'GET_QUESTIONS_ON_PAGE_LOAD';
export const UPDATE_LOADER = 'UPDATE_LOADER';
export const SET_QUESTION_PREVIEW = 'SET_QUESTION_PREVIEW';
export const GET_EXAM_BY_ID_SUCCESS = 'GET_EXAM_BY_ID_SUCCESS';
export const POST_SEARCH_EXAM = 'POST_SEARCH_EXAM';
export const POST_SEARCH_EXAM_SUCCESS = 'POST_SEARCH_EXAM_SUCCESS';
export const CLEAR_POST_EXAM = 'CLEAR_POST_EXAM';
export const POST_PURCHASE_STATUS = 'POST_PURCHASE_STATUS';
export const POST_PURCHASE_STATUS_SUCCESS = 'POST_PURCHASE_STATUS_SUCCESS';
export const POST_CONTACT_US_DETAIL = 'POST_CONTACT_US_DETAIL';
export const POST_PAGE_AUDIT = 'POST_PAGE_AUDIT';
export const POST_PAYPAL_PAYMENT_INITIATE = 'POST_PAYPAL_PAYMENT_INITIATE';
export const POST_CHECK_COUPON_CODE = 'POST_CHECK_COUPON_CODE';
export const POST_CHECK_COUPON_CODE_SUCCESS = 'POST_CHECK_COUPON_CODE_SUCCESS';
export const POST_CHECK_COUPON_CODE_FAILURE = 'POST_CHECK_COUPON_CODE_FAILURE';
export const GET_TENANT_DETAIL_BY_CODE = 'GET_TENANT_DETAIL_BY_CODE';
export const GET_TENANT_DETAIL_BY_CODE_SUCCESS = 'GET_TENANT_DETAIL_BY_CODE_SUCCESS';
export const GET_TENANT_DETAIL_BY_CODE_FAILURE = 'GET_TENANT_DETAIL_BY_CODE_FAILURE';
export const GET_LEARNING_SET = 'GET_LEARNING_SET';
