import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { postPageAudit } from '../../store/actions';
import { useStyle } from '../../styles/style';
const LearningExamTopBar = ({ exam }) => {
    const classes = useStyle();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    return (React.createElement("div", { className: classes.questionTopBar },
        React.createElement("span", null, exam.examName),
        React.createElement(IconButton, { size: "small", onClick: () => {
                dispatch(postPageAudit({ page: 'STUDENT PORTAL:Exit Learning Exam' }));
                navigate('/');
            } },
            React.createElement(Close, null))));
};
export default LearningExamTopBar;
