export const PORTALS = {
    TEACHER_PORTAL: 'TEACHER_PORTAL',
    STUDENT_PORTAL: 'STUDENT_PORTAL',
};
export const EXAM_PREVIEW = {
    RESULT: 'RESULT',
    EXAM: 'EXAM',
};
export const EXAM_TYPE = {
    CONTINUE_EXAM: 'CONTINUE_EXAM',
    START_EXAM: 'START_EXAM',
    VIEW_RESULT: 'VIEW_RESULT',
    LEARNING: 'LEARNING',
};
export const QUESTION_RESULT_TYPE = {
    NOT_ANSWERED: 'NOT_ANSWERED',
    CORRECT: 'CORRECT',
    WRONG: 'WRONG',
};
export const TAB_CONST = {
    allExams: 'ALL Exams',
    upcoming_exams: 'Upcoming Exams',
    completed_exams: 'Completed Exams',
};
