import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import LearningExam from '.';
import Loader from '../../UIElements/Icons/Loader';
import * as Actions from '../../store/actions';
import { useStyle } from '../../styles/style';
const LearningMainExam = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { loader } = useSelector((state) => state.exam);
    const { learningSetLoader } = loader;
    const { examID, examSetID, type, transactionID } = location.state;
    const classes = useStyle();
    useEffect(() => {
        if (!examID)
            navigate('/');
        dispatch(Actions.getQuestionPageLoad({
            params: { examID, examSetID },
        }));
    }, []);
    return learningSetLoader ? (React.createElement(Loader, { loaderClass: classes.loader })) : (React.createElement(LearningExam, { examID: examID, examSetID: examSetID }));
};
export default LearningMainExam;
